import React from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import ImageIsms from '../images/isms.jpg'

const policies = [
    '情報資産の機密性、完全性、可用性を確実に保護するために組織的、技術的に適切な対策を講じ、変化する情報技術や新たな脅威に対応する。',
    '全社員に情報セキュリティ教育の実施と方針の周知徹底をはかり、意識の高揚・維持に務める。',
    'マネジメントシステム及び、情報セキュリティに関する目的を設定し、定期的にレビューし、継続的に改善を実施し、維持する。',
    'マネジメントシステムを実行・維持・改善して行くために管理責任者に責任と権限を委譲する。'
]

export default function Production() {
    return (
        <>
            <Helmet
                title="情報セキュリティ基本方針 - マコム・プラニング公式サイト"
                description="マコム・プラニングの情報セキュリティ基本方針に関するページです。"
            />
            <Layout>
                {/* Hero Part */}
                <section className="relative py-40">
                    <div className="absolute top-0 left-0 lg:w-4/5 w-full h-full bg-gray-100 rounded-r-full" />
                    <div className="relative container px-4 h-full mx-auto">
                        <div className="relative flex flex-wrap items-center justify-center -mx-4">
                            <div className="relative z-10 w-full md:w-auto lg:w-3/5 px-4">
                                <div className="mx-auto text-center">
                                    <span className="bg-gradient-to-br from-slate-800 via-slate-400 to-slate-700 bg-clip-text text-transparent font-semibold">Information and Security Policy</span>
                                    <h1 className="mt-8 mb-6 lg:mb-10 text-4xl font-semibold">情報セキュリティ基本方針</h1>
                                    <h2 className="mb-8 lg:mb-12 text-gray-600">マコム・プラニング株式会社について、第一にお客様はもとよりシステム開発保守及び販売・運営管理事業・キャンペーン事務局事業に関わる全ての人々の満足を得る事を目標とし、信頼・安心される会社を目指します。</h2>
                                    <Link id='enquiry' className="px-10 py-4 text-sm leading-normal ring-1 ring-slate-600 text-slate-600 hover:bg-slate-600 hover:text-white duration-500 rounded-full" to="/#enquiry">お問い合わせはこちら</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Body part */}
                <section className="py-16 px-4 md:px-10">
                    <div className="max-w-6xl mx-auto text-center grid sm:grid-cols-2 justify-center group">
                        <div className="">
                            <p className="text-4xl font-semibold">行動指針</p>
                            <ul className="mt-8 text-left">
                                {policies.map((p, index) => {
                                    return (
                                        <li className="mb-4" key={index}>
                                            <span className="text-2xl font-semibold">{index + 1}.</span>
                                            <span className="ml-4">{p}</span>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <img className="mx-auto my-auto" src={ImageIsms} alt="ISMS" width="auto" />
                    </div>
                </section>



                {/* API based gallery */}
                {/* <section className="pb-20">
                    <div className="container mx-auto px-4">
                        <div className="flex flex-wrap -m-3">
                            {data.allContentfulProductionDepartment.nodes.map((g, index) => {
                                return (
                                    <div className="w-full lg:w-1/2 p-3" key={index}>
                                        <GatsbyImage className="w-full h-max rounded-lg object-cover" image={getImage(g.photo.gatsbyImageData)} alt={g.title} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section> */}


            </Layout>
        </>
    )
}